import {createApp} from 'vue'
import App from './App.vue'
import router from '@/router/index'
import {piniaStore} from './store'
import '@/assets/styles/globals.scss'
import 'virtual:svg-icons-register'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'

const screenAdapter = import.meta.env.VITE_APP_OPEN_SCREEN_ADAPTER;
const app = createApp(App);

// 使用组件
app.use(ElementPlus, {locale: zhCn, size: "default"});
app.use(router);
app.use(piniaStore);
// 挂载根结点
app.mount('#app');

/**
 * 挂在字体大小适配 基于宽度1366的设计稿进行适配
 * @param px 被转换的像素
 * @returns {number}
 * 开启字体适配后，将根据屏幕大小调整字体
 * 不开启则不生效
 */
app.config.globalProperties.$px = (px) => {
    if (screenAdapter === "true") {
        px = px / 1366 * document.body.clientWidth;
    }
    return px;
}

