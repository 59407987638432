import {defineStore} from 'pinia'
import {piniaStore} from '@/store'
import {deepClone, isExternalLink} from '@/util'
import {useAccountOutsideStore} from './account'


/**
 * 判断是否拥有当前路由权限
 * @param permissions
 * @param route
 * @returns {*}
 */
const hasPermission = (permissions, route) => {
    let isAuth = false
    if (route.meta && route.meta.auth) {
        isAuth = permissions.some(auth => {
            if (typeof route.meta.auth == 'string') {
                return route.meta.auth === auth
            } else {
                return route.meta.auth.some(routeAuth => {
                    return routeAuth === auth
                })
            }
        })
    } else {
        isAuth = true
    }
    return isAuth
}

/**
 * 根据当前权限过滤异步路由
 * @param routes
 * @param permissions
 * @returns {[]}
 */
const filterAsyncRoutes = (routes, permissions) => {
    const res = []
    routes.forEach(route => {
        let tmpRoute = deepClone(route)
        if (hasPermission(permissions, tmpRoute)) {
            if (tmpRoute.children) {
                tmpRoute.children = filterAsyncRoutes(tmpRoute.children, permissions)
                tmpRoute.children.length && res.push(tmpRoute)
            } else {
                res.push(tmpRoute)
            }
        }
    })
    return res;
}

/**
 * 将多层嵌套路由处理成平级
 * @param routes
 * @param breadcrumb
 * @param baseUrl
 * @returns {[]}
 */
const flatAsyncRoutes = (routes, breadcrumb, baseUrl = '') => {
    let res = []
    routes.forEach(route => {
        if (route.children) {
            let childrenBaseUrl = ''
            if (baseUrl === '') {
                childrenBaseUrl = route.path
            } else if (route.path !== '') {
                childrenBaseUrl = `${baseUrl}/${route.path}`
            }
            let childrenBreadcrumb = deepClone(breadcrumb)
            if (route.meta.breadcrumb !== false) {
                childrenBreadcrumb.push({
                    path: childrenBaseUrl,
                    title: route.meta.title
                })
            }
            let tmpRoute = deepClone(route)
            tmpRoute.path = childrenBaseUrl
            tmpRoute.meta.breadcrumbNeste = childrenBreadcrumb
            delete tmpRoute.children
            res.push(tmpRoute)
            let childrenRoutes = flatAsyncRoutes(route.children, childrenBreadcrumb, childrenBaseUrl)
            childrenRoutes.map(item => {
                // 如果 path 一样则覆盖，因为子路由的 path 可能设置为空，导致和父路由一样，直接注册会提示路由重复
                if (res.some(v => v.path === item.path)) {
                    res.forEach((v, i) => {
                        if (v.path === item.path) {
                            res[i] = item
                        }
                    })
                } else {
                    res.push(item)
                }
            })
        } else {
            let tmpRoute = deepClone(route)
            if (baseUrl !== '' && !isExternalLink(tmpRoute.path)) {
                if (tmpRoute.path !== '') {
                    tmpRoute.path = `${baseUrl}/${tmpRoute.path}`
                } else {
                    tmpRoute.path = baseUrl
                }
            }
            // 处理面包屑导航
            let tmpBreadcrumb = deepClone(breadcrumb)
            if (tmpRoute.meta.breadcrumb !== false) {
                tmpBreadcrumb.push({
                    path: tmpRoute.path,
                    title: tmpRoute.meta.title
                })
            }
            tmpRoute.meta.breadcrumbNeste = tmpBreadcrumb
            res.push(tmpRoute)
        }
    })
    return res
}

/**
 * 路由状态量
 */
export const useRouteStore = defineStore(
    // 唯一ID
    'route',
    {
        state: () => ({
            /**
             * 路由生成状态
             */
            isGenerate: false,
            /**
             * 路由列表
             */
            routes: [],
            /**
             * 当前移除的路由
             */
            currentRemoveRoutes: []
        }),
        getters: {
            /**
             * 扁平化路由（将三级及以上路由数据拍平成二级）
             * @param state
             * @returns {[]}
             */
            flatRoutes: state => {
                let routes = []
                if (state.routes) {

                    state.routes.map(item => {
                        routes.push(...deepClone(item.children))
                    });
                    routes.map(item => {
                        if (item.children) {
                            item.children = flatAsyncRoutes(item.children, [{
                                path: item.path,
                                title: item.meta.title
                            }], item.path)
                        }
                    });

                }
                return routes
            }
        },
        actions: {
            /**
             * 根据权限动态生成路由（前端生成）
             * @param asyncRoutes
             * @returns {Promise<unknown>}
             */
            generateRoutesAtFront(asyncRoutes) {
                return new Promise(async resolve => {
                    const userStore = useAccountOutsideStore();
                    // 如果权限功能开启，则需要对路由数据进行筛选过滤
                    const permissions = await userStore.permissions;
                    let accessedRoutes = filterAsyncRoutes(asyncRoutes, permissions)
                    // 设置 routes 数据
                    this.isGenerate = true
                    this.routes = accessedRoutes.filter(item => item.children.length !== 0)
                    resolve();
                })
            },
            // 记录 accessRoutes 路由，用于登出时删除路由
            setCurrentRemoveRoutes(routes) {
                this.currentRemoveRoutes = routes
            },
            // 清空路由
            removeRoutes() {
                this.isGenerate = false
                this.routes = []
                this.currentRemoveRoutes.forEach(removeRoute => {
                    removeRoute();
                })
                this.currentRemoveRoutes = []
            }
        }
    }
);


export function useRouteOutsideStore() {
    return useRouteStore(piniaStore)
}
