import path from 'path-browserify'


/**
 * 深拷贝
 * @param target
 * @returns {{}}
 */
export function deepClone(target) {
    let result;
    if (typeof target === 'object') {
        if (Array.isArray(target)) {
            result = []
            for (let i in target) {
                result.push(deepClone(target[i]))
            }
        } else if (target === null) {
            result = null
        } else if (target.constructor === RegExp) {
            result = target
        } else {
            result = {}
            for (let i in target) {
                result[i] = deepClone(target[i])
            }
        }
    } else {
        result = target
    }

    return result
}

/**
 * 判断两个对象是否相等
 * @param objA
 * @param objB
 * @returns {boolean}
 */
export function deepEqual(objA, objB) {
    //相等
    if (objA === objB) {
        return objA !== 0 || 1 / objA === 1 / objB;
    }
    //空判断
    if (objA == null || objB == null) {
        return objA === objB;
    }
    //类型判断
    if (Object.prototype.toString.call(objA) !== Object.prototype.toString.call(objB)) {
        return false;
    }

    switch (Object.prototype.toString.call(objA)) {
        case '[object RegExp]':
        case '[object String]':
            //字符串转换比较
            return '' + objA === '' + objB;
        case '[object Number]':
            //数字转换比较,判断是否为NaN
            if (+objA !== +objA) {
                return +objB !== +objB;
            }
            return +objA === 0 ? 1 / +objA === 1 / objB : +objA === +objB;
        case '[object Date]':
        case '[object Boolean]':
            return +objA === +objB;
        case '[object Array]':
            //判断数组
            for (let i = 0; i < objA.length; i++) {
                if (!deepEqual(objA[i], objB[i])) return false;
            }
            return true;
        case '[object Object]':
            //判断对象
            let keys = Object.keys(objA);
            for (let i = 0; i < keys.length; i++) {
                if (!deepEqual(objA[keys[i]], objB[keys[i]])) return false;
            }

            keys = Object.keys(objB);
            for (let i = 0; i < keys.length; i++) {
                if (!deepEqual(objA[keys[i]], objB[keys[i]])) return false;
            }

            return true;
        default :
            return false;
    }
}

/**
 * 判断当前登陆的用户是否有permission
 * @param permission
 * @returns {boolean|*}
 */
const hasPermission = (permission) => {
    // 预留接口
    return true;
}

/**
 * 鉴权
 * @param value
 * @returns {*|boolean}
 */
export function auth(value) {
    let auth
    if (typeof value === 'string') {
        auth = hasPermission(value)
    } else {
        auth = value.some(item => {
            return hasPermission(item)
        })
    }
    return auth
}

/**
 * 鉴权所有
 * @param value
 * @returns {*}
 */
export function authAll(value) {
    return value.every(item => {
        return hasPermission(item)
    })
}

/**
 * 是否为外链
 * @param path
 * @returns {boolean}
 */
export function isExternalLink(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 计算路由路径
 * @param basePath
 * @param routePath
 * @returns {string|*}
 */
export function resolveRoutePath(basePath, routePath) {
    if (isExternalLink(routePath)) {
        return routePath
    }
    if (isExternalLink(basePath)) {
        return basePath
    }
    return basePath ? path.resolve(basePath, routePath) : routePath
}
