import axios from 'axios'
import router from '@/router/index'
import {ElMessage} from 'element-plus'
import {useAccountOutsideStore} from '@/store/account'

const toLogin = () => {
    ElMessage({msg: "未登录", type: 'error'})
}

const api = axios.create({
    baseURL: import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true' ? '/api' : import.meta.env.VITE_APP_API_BASEURL,
    timeout: 30000,
    responseType: 'json',
    withCredentials: true,
});

api.interceptors.response.use(
    response => {
        /**
         * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
         * 假设返回数据格式为：{ code: 0, msg: '', data: '' }
         * 规则是当 code 为 0 时表示请求成功，为 9 时表示接口需要登录或者登录状态失效，需要重新登录
         * 请求出错时 msg 会返回错误信息
         */
        switch (response.data.code) {
            // 请求成功
            case 0:
                return Promise.resolve(response.data)
            // 需要登陆
            case 9:
                toLogin()
                break
            // 其他错误
            case 1:
            // 参数错误
            case 8:
            default:
                ElMessage({message: response.data.msg, type: 'error'})
        }
        return Promise.reject((response.data))
    },
    error => {
        let message = error.message
        if (message === 'Network Error') {
            message = '网络故障'
        } else if (message.includes('timeout')) {
            message = '接口请求超时'
        } else if (message.includes('Request failed with status code')) {
            message = '接口' + message.substr(message.length - 3) + '异常'
        }
        ElMessage({
            message,
            type: 'error'
        })

        return Promise.reject({
            msg: message
        })
    }
)

export default api
