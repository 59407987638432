import {createRouter, createWebHashHistory} from 'vue-router'
import {useAccountOutsideStore} from '@/store/account'
import {useMenuOutsideStore} from '@/store/menu'
import {useRouteOutsideStore} from '@/store/route'
import {accountApi} from "@/api/account"
import realtime from "@/router/realtime"
import realscene from "@/router/realscene"
import dynamic from "@/router/dynamic"

// 固定路由
let constantRoutes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login/index.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/',
        component: () => import('@/containers/index.vue'),
        redirect: '/dashboard',
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/pages/home/index.vue'),
                meta: {}
            },
        ]
    }
]

// 动态路由（异步路由、导航栏路由）
let asyncRoutes = [
    {
        meta: {
            title: '菜单',
            icon: 'sidebar-default'
        },
        children: [
            dynamic,
            realscene,
            realtime,
        ]
    }
];

// 其他路由
const lastRoute = {
    path: '/:all(.*)*',
    name: 'notFound',
    component: () => import('@/pages/error/404.vue'),
    meta: {
        title: '找不到页面'
    }
}

/**
 * 创建路由
 */
const router = createRouter({
    history: createWebHashHistory(),
    routes: constantRoutes
});

/**
 * 路由守卫
 */
router.beforeEach(async (to, from, next) => {
    const routeStore = useRouteOutsideStore();
    const menuStore = useMenuOutsideStore();

    // 请求登陆状态
    const isLogin = await accountApi.status();

    // 是否已登录
    if (isLogin) {
        // 是否已根据权限动态生成并挂载路由
        if (routeStore.isGenerate) {
            if (to.name) {
                if (to.matched.length !== 0) {
                    // 如果已登录状态下，进入登录页会强制跳转到控制台页面
                    if (to.name === 'login') {
                        next({
                            name: 'dashboard',
                            replace: true
                        });
                    } else if (to.name === 'dashboard') {
                        // 则默认进入侧边栏导航第一个模块
                        if (menuStore.sidebarMenus.length > 0) {
                            next({
                                path: menuStore.sidebarMenusFirstDeepestPath,
                                replace: true
                            });
                        } else {
                            next();
                        }
                    } else {
                        next();
                    }
                } else {
                    /**
                     * 如果是通过 name 跳转，并且 name 对应的路由
                     * 没有权限时，需要做这步处理，手动指向到 404 页面
                     */
                    next({
                        path: '/404'
                    });
                }
            } else {
                // 不是路由中的页面手动指向到 404 页面
                next({
                    path: '/404'
                });
            }
        } else {
            await routeStore.generateRoutesAtFront(asyncRoutes);
            let removeRoutes = [];
            routeStore.flatRoutes.forEach(route => {
                if (!/^(https?:|mailto:|tel:)/.test(route.path)) {
                    removeRoutes.push(router.addRoute(route));
                }
            })
            removeRoutes.push(router.addRoute(lastRoute));
            /**
             * accessRoutes 路由数据，在登出时会使用到，不使用 router.removeRoute
             * 是考虑配置的路由可能不一定有设置 name
             * 则通过调用 router.addRoute() 返回的回调进行删除
             */
            routeStore.setCurrentRemoveRoutes(removeRoutes);
            next({...to, replace: true});
        }
    } else {
        if (to.name !== 'login') {
            next({
                name: 'login',
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            next();
        }
    }
})

export default router
